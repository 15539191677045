<template>
  <div class="schedule" v-if="party && signup" :key="locale">
    <PartyHeader />
    <div class="schedule__tz-picker">
      <label>{{ $t('display_timezone') }}</label>
      <select v-model="currentTimezone">
        <option
          v-for="(timezone, index) in timezones"
          :key="`tz-picker-${index}`"
          :value="timezone"
        >{{ timezone }}</option>
      </select>
    </div>
    <div class="schedule__instructions">{{ $t('instructions') }}</div>
    <div class="schedule__list--container">
      <table class="schedule__list">
        <thead>
        <tr>
          <th>{{ $t('time_slot') }}</th>
          <th class="schedule__list--player-column  schedule__list--self">{{ signup.name }}</th>
          <th
            class="schedule__list--player-column"
            v-for="s in signups"
            :key="`signups-column-${s.id}`"
          >{{ s.name }}</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="slot in party.timeslots"
          :key="`slot-${slot.id}`"
        >
          <td>
            {{ formatDate(slot.date) }}
            <template v-if="worksForEveryone(slot)">
              <br />
              <div class="schedule__works">{{ $t('works_for_everyone') }}</div>
            </template>
          </td>
          <td class="schedule__list--player-column schedule__list--self">
            <img
              class="schedule__icon clickable"
              :src="icons[isChecked(slot.id, signup.id) ? 'check' : 'cancel']"
              :alt="isChecked(slot.id, signup.id) ? $t('available') : $t('unavailable')"
              :title="isChecked(slot.id, signup.id) ? $t('available') : $t('unavailable')"
              @click="toggleAvailability(slot.id)"
            />
          </td>
          <td
            class="schedule__list--player-column"
            v-for="s in signups"
            :key="`signups-row-${slot.id}-column-${s.id}`"
          >
            <img
              class="schedule__icon"
              :src="icons[isChecked(slot.id, s.id) ? 'check' : 'cancel']"
              :alt="isChecked(slot.id, s.id) ? $t('available') : $t('unavailable')"
              :title="isChecked(slot.id, s.id) ? $t('available') : $t('unavailable')"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import PartyHeader from '../components/PartyHeader.vue';
import check from '../assets/check.svg';
import cancel from '../assets/cancel.svg';

export default {
  components: { PartyHeader },

  data() {
    return {
      currentTimezone: null,
      icons: { check, cancel },
    };
  },

  computed: {
    ...mapGetters({
      party: 'getParty',
      signup: 'getSignup',
    }),
    timezones() {
      return moment.tz.names();
    },
    signups() {
      if (!this.signup || !this.party || !this.party.signups) {
        return [];
      }
      return this.party.signups.filter((signup) => signup.id !== this.signup.id);
    },
    slots() {
      if (!this.party || !this.party.timeslots) {
        return [];
      }
      return this.party.timeslots;
    },
    locale() {
      return !this.party || !this.party.locale ? 'fr' : this.party.locale;
    },
  },

  methods: {
    isChecked(slot, signup) {
      const slotData = this.slots.find((s) => s.id === slot);
      if (!slotData || !slotData.signups) {
        return false;
      }
      return slotData.signups.map((s) => s.id).includes(signup);
    },
    worksForEveryone(slot) {
      if (!this.party || !this.party.signups) {
        return false;
      }
      return this.party.signups.reduce((acc, signup) => {
        if (!slot.signups.map((s) => s.id).includes(signup.id)) {
          return false;
        }
        return acc;
      }, true);
    },
    toggleAvailability(timeslot) {
      const payload = {
        timeslot,
        signup: this.signup.uuid,
        party: this.party.uuid,
      };
      this.$store.dispatch('toggleAvailability', payload);
    },
    formatDate(item) {
      if (!item || !this.currentTimezone) {
        return '';
      }
      return moment(item).tz(this.currentTimezone).format(this.$t('date_format'));
    },
  },

  mounted() {
    this.$store.dispatch('fetchParty', this.$route.params.party).then(() => {
      const { language } = this.party;
      moment.locale(language);
      this.$i18n.locale = language;
      this.$store.dispatch('fetchSignup', this.$route.params.signup);
    });
    this.currentTimezone = moment.tz.guess();
  },
};
</script>

<style lang="scss">
.schedule {
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px;

  &__tz-picker {
    margin: 30px auto;
    max-width: 300px;

    label {
      font-weight: 600;
      line-height: 22px;
      font-size: 12px;
      margin-left: 15px;
    }

    select {
      background: #f1f1f1;
      width: 100%;
      padding: 10px 15px;
      border-radius: 15px;
      border: 1px solid #E6E6E8;
    }
  }

  &__instructions {
    width: 100%;
    text-align: left;
  }

  &__list {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ffffff;
    border-collapse: collapse;

    &--container {
      width: 100%;
      overflow-x: auto;
      padding: 30px 0;
    }

    th, td {
      padding: 15px;
      border: 1px solid #ffffff;
      vertical-align: middle;
    }

    th {
      text-align: left;
      font-weight: bold;
    }

    &--player-column {
      max-width: 100px;
      line-break: auto;
    }

    &--self {
      background: #395069;
    }
  }

  &__works {
    font-size: 18px;
    color: #56b545;
    margin-top: 10px;
  }

  &__icon {
    height: 30px;
    width: 30px;

    &.clickable {
      cursor: pointer;
    }
  }
}
</style>
